import { createSlice } from '@reduxjs/toolkit'
import { addKitchenTemplate, deleteKitchenTemplate, editKitchenTemplate, singlekitchenTemplateView, addDryWallTemplate, deleteDryWallTemplate, editDryWallTemplate, singleDryWallTemplate, switchingTemplate, listDryWallTemplate, listKitchenTemplate } from 'redux/service/settingsService/templatesService';

const initialState = {
  listkitchenTemplatRes: { data: [], isFetching: false, error: null },
  addkitchenTemplateRes: { data: [], isFetching: false, error: null },
  editkitchenTemplateRes: { data: [], isFetching: false, error: null },
  singlekitchenTemplateViewRes: { data: [], isFetching: false, error: null },
  deletekitchenTemplateViewRes: { data: [], isFetching: false, error: null },
  listdrywallTemplateRes: { data: [], isFetching: false, error: null },
  adddrywallTemplateRes: { data: [], isFetching: false, error: null },
  editdrywallTemplateRes: { data: [], isFetching: false, error: null },
  singledrywallTemplateViewRes: { data: [], isFetching: false, error: null },
  deletedrywallTemplateViewRes: { data: [], isFetching: false, error: null },
  switchingTemplateViewRes: { data: [], isFetching: false, error: null },
}

export const templatesSlice = createSlice({
  name: 'templatesSlice',
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      { api: listKitchenTemplate, name: "listkitchenTemplatRes" },
      { api: addKitchenTemplate, name: "addkitchenTemplateRes" },
      { api: deleteKitchenTemplate, name: "deletekitchenTemplateViewRes" },
      { api: editKitchenTemplate, name: "editkitchenTemplateRes" },
      { api: singlekitchenTemplateView, name: "singlekitchenTemplateViewRes" },
      { api: listDryWallTemplate, name: "listdrywallTemplateRes" },
      { api: addDryWallTemplate, name: "adddrywallTemplateRes" },
      { api: deleteDryWallTemplate, name: "deletedrywallTemplateViewRes" },
      { api: editDryWallTemplate, name: "editdrywallTemplateRes" },
      { api: singleDryWallTemplate, name: "singledrywallTemplateViewRes" },
      { api: switchingTemplate, name: "switchingTemplateViewRes" },

    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { templatesDispatch } = templatesSlice.actions

import { createSlice } from '@reduxjs/toolkit'
import { getDashboardDetails } from 'redux/service/dashboardService/dashboardService';

const initialState = {
  dashboardRes: { data: [], isFetching: false, error: null },
}

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  extraReducers: (builder) => {
    // builder
    const asyncActionCases = [
      { api: getDashboardDetails, name: "dashboardRes" },
    ];
    asyncActionCases.map((asyncAction) => {
      builder
        .addCase(asyncAction.api.fulfilled, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].data = payload;
        })
        .addCase(asyncAction.api.pending, (state) => {
          state[asyncAction.name].isFetching = true;
          state[asyncAction.name].error = null;
        })
        .addCase(asyncAction.api.rejected, (state, { payload }) => {
          state[asyncAction.name].isFetching = false;
          state[asyncAction.name].error = payload;
        });
    });
  },
})


export const { dashboardDispatch } = dashboardSlice.actions

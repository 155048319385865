import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../utils/api";

// GET DASHBOARD DETAILS
export const getDashboardDetails = createAsyncThunk(
  'get-dashboard-details',
  async (params, thunkAPI) => {
    console.log(params)
    try {
      const response = await API.get(`/dashboard-details`, { params });
      return response
    }
    catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  })
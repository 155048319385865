import {
  loginTitleImage,
  loginlogoImage,
  Logo,
  dloginTitleImage,
  dloginlogoImage,
  dLogo,
  companyLogo1,
  companyLogo2
} from "../helpers/images";


export function authLogo(url) {
  if (url === "CTS") {
    return {
      "src": loginlogoImage,
      "width": 200,
      "height": "auto"
    }
  } else if (url === "DURHAM") {
    return {
      "src": dloginlogoImage,
      "width": 70,
      "height": "auto"
    }
  } else {
    return true
  }
}

export function authTitleLogo(url) {
  if (url === "CTS") {
    return {
      "src": loginTitleImage,
      "width": "auto",
      "height": "auto"
    }
  } else if (url === "DURHAM") {
    return {
      "src": dloginTitleImage,
      "width": 100,
      "height": "auto"
    }
  } else {
    return true
  }
}

export function dashboardLogo(url) {
  if (url === "CTS") {
    return {
      "src": Logo,
      "width": "80px",
      "height": "auto"
    }
  } else if (url === "DURHAM") {
    return {
      "src": dLogo,
      "width": "45px",
      "height": "auto"
    }
  } else {
    return true
  }
}

export function dashboardIcon(url) {
  if (url === "CTS") {
    return {
      "src": companyLogo1,
      "width": '53px',
      "height": "auto"
    }
  } else if (url === "DURHAM") {
    return {
      "src": companyLogo2,
      "width": "53px",
      "height": "auto"
    }
  } else {
    return true
  }
}
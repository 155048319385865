import { createSlice } from "@reduxjs/toolkit";
import { createAsyncActionHandlers } from "helpers/asyncActionHandler";
import {
  customerList,
  customerGetById,
  customerCreate,
  customerUpdate,
  customerSingleView,
  customerTypeCreate,
  jobCategoryCreate,
  customerDelete,
  receivepaymentSingleView,
  creditmemoSingleView,
  overpaymentSingleView,
  customerRefundCreditMemoCreate,
  customerPricinggroup,
  unappliedSingleView,
  customerSaleList,
  customerTermUse,
  customerRebateHistory,
  customerRebateUpdate,
  customerCartList
} from "redux/service/menuService/customerService/customerService";

const initialState = {
  customerListRes: { data: [], isFetching: true, error: null },
  customerGetByIdRes: { data: [], isFetching: true, error: null },
  customerCreateRes: { data: [], isFetching: false, error: null },
  customerUpdateRes: { data: [], isFetching: false, error: null },
  customerTermUseRes: { data: [], isFetching: false, error: null },
  customerSingleViewRes: { data: [], isFetching: true, error: null },
  customerTypeCreateRes: { data: [], isFetching: false, error: null },
  jobCategoryCreateRes: { data: [], isFetching: false, error: null },
  customerDeleteRes: { data: [], isFetching: false, error: null },
  receivepaymentSingleViewRes: { data: [], isFetching: false, error: null },
  creditmemoSingleViewRes: { data: [], isFetching: false, error: null },
  overpaymentSingleViewRes: { data: [], isFetching: false, error: null },
  customerPricinggroupRes: { data: [], isFetching: false, error: null },
  customerRefundCreditMemoCreateRes: {
    data: [],
    isFetching: false,
    error: null,
  },
  unappliedSingleViewRes: { data: [], isFetching: true, error: null },
  customerSaleListRes: { data: [], isFetching: true, error: null },
  customerRebateHistoryRes: { data: [], isFetching: true, error: null },
  customerRebateUpdateRes: { data: [], isFetching: true, error: null },
  customerCartListRes: { data: [], isFetching: true, error: null },
};

export const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  extraReducers: (builder) => {
    const asyncActionCases = [
      { api: customerList, name: "customerListRes" },
      { api: customerGetById, name: "customerGetByIdRes" },
      { api: customerCreate, name: "customerCreateRes" },
      { api: customerUpdate, name: "customerUpdateRes" },
      { api: customerTermUse, name: "customerTermUseRes" },
      { api: customerSingleView, name: "customerSingleViewRes" },
      { api: customerTypeCreate, name: "customerTypeCreateRes" },
      { api: jobCategoryCreate, name: "jobCategoryCreateRes" },
      { api: customerDelete, name: "customerDeleteRes" },
      { api: receivepaymentSingleView, name: "receivepaymentSingleViewRes" },
      { api: creditmemoSingleView, name: "creditmemoSingleViewRes" },
      { api: overpaymentSingleView, name: "overpaymentSingleViewRes" },
      { api: customerPricinggroup, name: "customerPricinggroupRes" },
      {
        api: customerRefundCreditMemoCreate,
        name: "customerRefundCreditMemoCreateRes",
      },
      { api: unappliedSingleView, name: "unappliedSingleViewRes" },
      { api: customerRebateHistory, name: "customerRebateHistoryRes" },
      { api: customerRebateUpdate, name: "customerRebateUpdateRes" },
      { api: customerSaleList, name: "customerSaleListRes" },
      { api: customerCartList, name: "customerCartListRes" }
    ];

    asyncActionCases.map((asyncAction) => {
      createAsyncActionHandlers(builder, asyncAction);
    });
  },
});

export const { customerDispatch } = customerSlice.actions;

import {Group, Image, Menu, Text} from "@mantine/core";
import {SESSION, notify} from "helpers/Golbal";
import {getSessionStorage, unsetSessionStorage} from "helpers/SessionConfig";
import {myProfileIcon, notifyIcon, returnIcon} from "helpers/images";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {authLogout} from "redux/service/authService/authService";
import Styles from "styles/style-Api/style";

const CustomHeader = (props) => {
  const {isTimeout, setIsTimeout} = props;
  const {classes} = Styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = {
    name: getSessionStorage(SESSION.EMPLOYEE_NAME),
    image: "",
  };
  const handleLogout = async () => {
    try {
      const response = await dispatch(authLogout()).unwrap();
      notify(response);
      setIsTimeout(false);
      unsetSessionStorage();
      navigate("/login?path=/", {replace: true});
      window.location.reload();
    } catch (error) {
      notify(error);
    }
  };

  useEffect(() => {
    if (isTimeout === true) handleLogout();
  }, [isTimeout]);

  return (
    <>
      <Group position="right">
        <Image width={"15px"} src={notifyIcon} />
        <Text
          color="font-color.1"
          weight={500}
          size="sm"
          sx={{lineHeight: 1}}
          mr={3}
        >
          Hi, {user.name}
        </Text>
        <Menu
          position="top-end"
          offset={2}
          arrowPosition="center"
          classNames={classes}
          shadow="md"
          width={180}
        >
          <Menu.Target>
            <Image width={"40px"} src={myProfileIcon} />
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => handleLogout()}
              icon={<Image width={15} src={returnIcon} />}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </>
  );
};

export default CustomHeader;

import axios from "axios";
import {
  getSessionStorage,
  setSessionStorage,
  unsetSessionStorage,
} from "../helpers/SessionConfig";
import { SESSION, notify } from "../helpers/Golbal";

const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  // headers: { "ngrok-skip-browser-warning": 69420 }
});
let isRefreshing = false
const TOKEN_PAYLOAD_KEY = "authorization";
// const PUBLIC_REQUEST_KEY = "public-request";
const TOKEN = () => getSessionStorage(SESSION.TOKEN);

const errorMessage = {
  success: false,
  message: "Time Out! Kindly Login",
};

const refreshToken = async (token) => {
  try {
    setSessionStorage(SESSION.TOKEN, token);
    window.location.href = window.location.pathname;
  }
  catch (error) {
    notify(errorMessage)
    setTimeout(() => {
      window.location.href = "/login";
    }, 3000);
  }
}

API.interceptors.request.use(function (config) {
  if (TOKEN()) {
    config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${TOKEN()}`;
  }
  return config;
});

API.interceptors.response.use(
  // unwrap response data
  ({ data }) => data,

  // catch statusCode != 200 responses and format error
  (error) => {
    if (error.response) {
      const errorData = {
        ...error.response.data,
        status: error.response.status,
      };
      if (error.response.status === 401) {
        unsetSessionStorage();
        // notify(errorMessage);
        // setTimeout(() => {
        //   window.location.href = "/login";
        // }, 3000);
      }
      if (error.response.status === 403) {
        if (!isRefreshing) {
          isRefreshing = true
          refreshToken(error.response.data.error)
        }
      }
      return Promise.reject(errorData);
    }
    return Promise.reject({ message: error.message });
  }
);

export default API;

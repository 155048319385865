import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../utils/api";

export const mergeProductOrder = createAsyncThunk(
  "merge-product-order",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/mergeOrder`, bodyParams);
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const splitProductOrder = createAsyncThunk(
  "split-product-order",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/splitOrder`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const splitAdditionalInfo = createAsyncThunk(
  "split-additional-info",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.get(`/additionalinfo/${bodyParams}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getlabelOrder = createAsyncThunk(
  "get-label-Order",
  async (bodyParams, thunkAPI) => {
    try {
      const response = await API.post(`/getbillLabel`, bodyParams);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);